var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c("v-data-iterator", {
            attrs: { items: _vm.fullQueryData.result_urls },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-end" },
                      [
                        _c("GetTableData", {
                          staticClass: "mr-1",
                          attrs: { sqlText: _vm.fullQueryData.command },
                        }),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g({ attrs: { text: "" } }, on),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              color: "secondary",
                                              small: "",
                                              dark: "",
                                            },
                                          },
                                          [_vm._v("mdi-eye-outline")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold caption secondary--text",
                                          },
                                          [_vm._v("View Query")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.sqlDialog,
                              callback: function ($$v) {
                                _vm.sqlDialog = $$v
                              },
                              expression: "sqlDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "div",
                                    { staticClass: "secondary--text" },
                                    [
                                      _c("v-icon", { staticClass: "mr-1" }, [
                                        _vm._v("mdi-script-text-outline"),
                                      ]),
                                      _vm._v(" SQL Text "),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mb-1" }),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("CodeHighlight", {
                                      attrs: {
                                        language: "sql",
                                        code: _vm.fullQueryData.command,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "secondary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.sqlDialog = false
                                          },
                                        },
                                      },
                                      [_vm._v("close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "v-list",
                      { attrs: { "two-line": "" } },
                      _vm._l(props.items, function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { "two-line": "" } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c("v-icon", { attrs: { large: "" } }, [
                                      _vm._v("mdi-folder-zip"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "subtitle-2 secondary--text",
                                                            staticStyle: {
                                                              "text-decoration":
                                                                "none",
                                                            },
                                                            attrs: {
                                                              href: item,
                                                              color: "primary",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .split("/")
                                                                  .pop()
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Download")])]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              href: "https://docs.nuvolos.cloud/features/database-integration/view-tables#extracting-results",
                                                              target: "_blank",
                                                              rounded: "",
                                                              text: "",
                                                              outlined: "",
                                                              "x-small": "",
                                                              "e2e-queries-compressed-file":
                                                                "",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary",
                                                                  },
                                                                },
                                                                [_vm._v("info")]
                                                              ),
                                                              _vm._v(
                                                                " Compressed File "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "This is a compressed file, click here for instructions to extract it"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider", { attrs: { inset: "" } }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }